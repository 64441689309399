.scroll {
  background-color: #656565;
}

.scroll .p-datascroller .p-datascroller-content {
  background-color: transparent;
}

.scroll > .p-datascroller-content {
  background-color: transparent;
}

.p-accordion-header-link {
  background: transparent !important;
  border: 0px !important;
  border-bottom: 1px solid inherit !important;
}

.p-accordion-content {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}

.p-datascroller .p-datascroller-content {
  background-color: transparent !important;
  padding: 0 !important;
}

@media screen and (max-width: 3050px) {
  .main {
    display: none;
  }
  .mainWarning {
    width: 100vw;
    height: 100vh;
    background-color: red;
  }
}

@media screen and (max-width: 800px) {
  .main {
    display: block;
  }
  .mainWarning {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .p-col-8 {
    width: 62% !important;
  }
}

@keyframes cartAnimation {
  0% {
    background-color: orange;
    color: black;
    border: 1px solid orange;
  }
  50% {
    border: 1px solid red;
    background-color: red;
    color: white;
  }
  100% {
    border: 1px solid orange;
    background-color: orange;
    color: black;
  }
}

@keyframes pauseAnimation {
  0% {
    background-color: white;
    color: red;
    border: 1px solid white;
  }
  50% {
    border: 1px solid red;
    background-color: red;
    color: white;
  }
  100% {
    border: 1px solid white;
    background-color: white;
    color: red;
  }
}

@media screen and (max-width: 640px) {
  .steps-demo .p-steps {
    height: 350px;
  }
  .steps-demo .p-steps > ul {
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .steps-demo .p-steps > ul .p-steps-item {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .steps-demo .p-steps > ul .p-steps-item:before {
    position: static;
    left: auto;
    top: auto;
    margin-top: 0;
    border-left: 1px solid var(--surface-d);
    border-top: 0 none;
    width: auto;
    height: 100%;
    margin-left: 1rem;
  }
  .steps-demo .p-steps > ul .p-steps-item .p-menuitem-link {
    flex-direction: row;
    overflow: visible;
    height: 70px;
    padding: 10px;
    max-height: 50px;
    min-width: 150px;
    filter: dropdown(2px 4px 6px black) !important;
  }
  .steps-demo .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0.5rem 0;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child {
    flex-grow: 0;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
    padding: 10px;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child:before {
    display: none;
  }

  .p-steps-number {
    background-color: #323232 !important;
    color: white !important;
  }
}

.rc-steps-icon {
  color: black !important;
}

.rc-steps-item-wait {
  --animation: step 5s infinite !important;
}

@keyframes step {
  0% {
    background-color: orange;
    color: black;
  }
  50% {
    background-color: red;
    color: white;
  }
  100% {
    background-color: orange;
    color: black;
  }
}

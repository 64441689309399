#payment > span {
  color: white !important;
}

#mesaCombo > span {
  color: white !important;
  font-size: 25px;
  text-align: center;
  animation: paymotion 2s infinite !important;
}

#mesaCombo-ok > span {
  color: white !important;
  font-size: 30px;
  text-align: center;
}

.p-dialog .p-dialog-header {
  background-color: transparent !important;
}

.p-dialog .p-dialog-content {
  background-color: transparent !important;
}

.p-dialog .p-dialog-footer {
  background-color: transparent !important;
}

.p-placeholder {
  animation: paymotion 2s infinite !important;
}

@keyframes paymotion {
  0% {
    background-color: black;
    color: black;
  }
  50% {
    background-color: red;
    color: white;
  }
  100% {
    background-color: black;
    color: black;
  }
}

#Qr {
  width: 60%;
  height: 60%;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 5px 5px 18px -1px rgba(0, 0, 0, 0.28);
}

#divMesa > div {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}

@keyframes paymotion {
  0% {
    background-color: black;
    color: black;
  }
  50% {
    background-color: red;
    color: white;
  }
  100% {
    background-color: black;
    color: black;
  }
}

@keyframes entregamotion {
  0% {
    color: black;
  }
  50% {
    color: white;
  }
  100% {
    color: black;
  }
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  border: 0;
  background-color: transparent;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
  border-radius: 25px;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  background-color: transparent;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up {
  border-radius: 25px !important;
  align-self: center;
  width: 20px;
  height: 20px;
  box-shadow: 1px 2px 3px black;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  background-color: orange;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  align-self: center;
  box-shadow: 1px 2px 3px black;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
  border-radius: 25px !important;
  width: 20px;
  height: 20px;
}

.p-inputwrapper-focus {
  border: 0 !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

#combo-borda .p-dropdown .p-dropdown-label {
  color: white !important;
  font-weight: bold;
}
